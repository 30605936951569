import HandoutsActions from "@actions/HandoutsActions/handoutsActions";
import ExcelReportActions from "@actions/ReportActions/excelReportActions";
import DatePickerObjectNastix from "@components/pickers/DatePickerObjectNastix";
import { DialogComponentProps } from "@components/tables/Table/Table";
import { MessageContext } from "@contexts/MessageContext";
import { NumOfRequestsContext } from "@contexts/NumOfRequestsContext";
import VerifiedIcon from '@mui/icons-material/Verified'
import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { TableActionButon } from "@styles/Buttons";
import { DialogDivider, INastixDialog, INastixDialogTitle } from "@styles/Dialog";
import { DialogProps } from "@utils/dialogProps/DialogProps";
import { downloadFile } from "@utils/files/blobs";
import { convertDateToYYYYMMDD } from "@utils/utils/util";
import { Dayjs } from "dayjs";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const PrintShirtsFranchise = (props: DialogProps) => {
    const {ids, state_manager, onClose, open} = props

    // const { t } = useTranslation('handouts')
    const { t } = useTranslation('navigation')

    const {setMessage} = useContext(MessageContext)

    const {setNumOfRequests} = useContext(NumOfRequestsContext)

    const [disable_button, setDisableButton] = useState(false)
    const [show_children, setShowChildren] = useState(false)

    const handleSubmit = () => {
        setDisableButton(true)

        const excel_report_actions = new ExcelReportActions()
        const data = {
            franchise_ids: ids,
            franchise: true
        }

        
		excel_report_actions.createPackageSlip(data)
        .then(response => {
            console.log(response)
			
			setNumOfRequests(props => props - 1)
	
			let current_date = convertDateToYYYYMMDD(new Date())

			let filename = `Package Slip Report ${current_date}.xlsx`
			downloadFile(response, filename)
        })
		.catch(error => {
            console.error('Error downloading file:', error)
			setNumOfRequests(props => props - 1)
        })
    }

    const handleShowChildrenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowChildren(event.target.checked)
    }

    const handleClose = () => {
        onClose()
    }

    return (
        <INastixDialog onClose={handleClose} open={open}>
            <INastixDialogTitle> {t('print')} </INastixDialogTitle>
            <DialogDivider/>
            <Typography>
                {t('would_you_like_to_print_a_report_of_these_tshirts')}
            </Typography>
            {/* <FormControlLabel 
                control={
                    <Checkbox 
                        checked={show_children}
                        onChange={handleShowChildrenChange}
                    />
                } 
                label={t('show_children')}
            /> */}
            <Button disabled={disable_button} variant='contained' onClick={handleSubmit}> {t('confirm')} </Button>
        </INastixDialog>
    )
}

const PrintShirtsFranchiseHandler = (props: DialogComponentProps) => {
    const {disabled, ids, state_manager} = props

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const { t } = useTranslation('handouts')

    return (
        <>
            <TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<VerifiedIcon/>}>
                <Typography> {t('print')} </Typography>
            </TableActionButon>
            <PrintShirtsFranchise ids={ids} state_manager={state_manager} open={open} onClose={handleClose} />
        </>
    )
}

export default PrintShirtsFranchiseHandler