import {useContext} from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import Tooltip from '@mui/material/Tooltip'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

import { CRUDAction } from '@actions/Actions/actions'
import ChildForm from '@components/forms/ChildForm'
import Table from '@components/tables/Table/Table'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { UserPermissions } from '@utils/enums/permissions'
import { containsLetters, permissionValid } from '@utils/utils/util'
import { Child } from '@utils/interfaces/interfaces'
import {INASTIX_SHIRT_MAP} from '@components/tables/CRUD/ChildTable/index'

import { notAllocatedChildrenTableFilter } from './filter'

import '../styles.css'
import TransferClassesDialogHandler from '../TransferClassesDialogHandler'
import SendToMemberPoolDialogHandler from '../SendToMemberPoolDialogHandler'
import { NumOfChildrenNotAllocatedContext } from '@pages/Children/contexts/NumOfChildrenNotAllocated'
import ConfirmDeleteDialogHandler from '@components/dialogs/ConfirmDeleteDialogHandler'


export interface TableProps {
	action: CRUDAction
}

const gender_map: Record<number, string>  = {
	1: 'M',
	2: 'F', 
	3: 'Other'
}

export const valueConnectedSchoolGetter = (params: any) => {
	if (params.row.school_name) {
		return params.row.school_name
	}
	return ""
}

const valueGetter = (params: any) => {
	if (params.field === 'sex')
		return gender_map[params.value] || ''

	else if (params.field === 'shirt_size')
		return INASTIX_SHIRT_MAP[params.value] || ''
}

const ChildNotAllocatedTable = (props: TableProps) => {
	const {action} = props
    const { t } = useTranslation('children')

	const user = getLocalStorageUser()

	const {setNumOfChildrenNotAllocated} = useContext(NumOfChildrenNotAllocatedContext)

	//const can_edit = permissionValid(user, UserPermissions.EDIT_CHILDREN)
	const can_delete = permissionValid(user, UserPermissions.DELETE_CHILDREN)
	const can_transfer = permissionValid(user, UserPermissions.TRANSFER_CHILDREN)

	const columns = [
		{ field: 'id', headerName: t('id') },
		{ field: 'username', headerName: t('name'), minWidth: 200 },
		{ field: 'school_name', cellClassName: '', minWidth: 200, headerName: t('school'), valueGetter: valueConnectedSchoolGetter},
		{ field: 'age', sortable: false, filterable: false, headerName: t('age') },
		{ field: 'shirt_size', sortable: false, filterable: false, headerName: t('shirt_size'), valueGetter },
		{ field: 'parent_contact_email', sortable: false, headerName: t('parent_contact'), minWidth: 200 },
		{ field: 'sex', sortable: false, filterable: false, headerName: t('gender'), cellClassName: 'center-aligned-cell', maxWidth: 85, valueGetter },
 		{ field: 'allergies', sortable: false, filterable: false, headerName: t('allergies'), minWidth: 180, renderCell: (params: any) => {
			const data = params.row.allergies
			const menuItems = []
			const array_length = data.length

			 if (array_length === 0) 
				return <Typography variant='body2'></Typography>
			else if (array_length === 1)
				return <Typography variant='body2'>{data[0]}</Typography>
			else
				for (let i = 0; i < data.length; i++) {
					const option = data[i];
					menuItems.push(
					  <MenuItem key={i} value={option} style={{ cursor: 'auto' }}>
							{option}
					  </MenuItem>
					)
		  		}
				return <Select variant='standard' value={data[0]} style={{ fontSize: '0.9rem' }} fullWidth>{menuItems}</Select>

		} }, 

		{ field: 'authorized', sortable: false, filterable: false, headerName: t('authorised'), cellClassName: 'center-aligned-cell',
		renderCell: (params: any) => {
			if (params.value === 1) 
				return <CheckCircleIcon sx={{ color: '#51b77f' }} />
			else if (params.value === 2) 
				return <Tooltip title={t('pending_authorisation')}><PendingActionsIcon sx={{ color: 'orange' }} /></Tooltip>
			else if (params.value === 3) 
				return <CancelIcon sx={{ color: '#ed586e' }} />
			else if (params.value === 4) 
				return <Tooltip title={"Pending payment"}><PendingActionsIcon sx={{ color: 'orange' }} /></Tooltip>
		},
		},
		{ field: 'start_date', sortable: false, filterable: false, headerName: t('start_date') },
		{ field: 'birthday', sortable: false, filterable: false, headerName: t('birthday') },
	]

	const paginationFilterParser = (field: string, value: string) => {
		let custom_operator = undefined

		if (field === 'school_name') {
			field = 'tenant__tenant_name'
		}
		else if (field === 'preferred_parent_contact_detail') {
			if (containsLetters(value))
				field = 'preferred_parent_contact__email'
			else
				field = 'preferred_parent_contact__phone_number'
		}
		return {field, value, custom_operator}
	}

	const paginationSortParser = (field: string) => {
		if (field === 'school_name') {
			field = 'tenant__tenant_name'
		}
		return field
	}

	const makeData = (children: Child[]) => {
		return notAllocatedChildrenTableFilter(children)
	}

    return (
        <Table
			single_selection_dialogs={can_transfer ? [TransferClassesDialogHandler] : []} 
			dialogs={[
				...(can_transfer ? [] : []),
				(props) => SendToMemberPoolDialogHandler({ ...props, setNumState: setNumOfChildrenNotAllocated }),
				...(can_delete ? [ConfirmDeleteDialogHandler] : [])
			]}
			Form={ChildForm} 
			action={action} 
			columns={columns} 
			can_edit={false}
			filter={{has_school: true, company_class__isnull: true, archive: false, is_old: false}}
			include_add_operation={false}
			page_size={100}
			use_pagination={true}
			amount_of_rows_selection={[100]}
			paginationFilterParser={paginationFilterParser}
			paginationSortParser={paginationSortParser}
			makeData={makeData}
		/>
    )
}

export default ChildNotAllocatedTable