import { useTranslation } from 'react-i18next'


import Table, { DialogComponent } from '@components/tables/Table/Table'
import { CRUDAction } from '@actions/Actions/actions'
import { capitalizeFirstLetter, getWordBeforeAt, isValidEmail } from '@utils/utils/util'
import { HandoutType } from '@utils/interfaces/interfaces'


export interface TableProps {
	action: CRUDAction
	dialogs: DialogComponent[]
    handout: HandoutType
    filter: Object
}


const valuePreferredContactGetter = (params: any) => {
	const contact_name = params.value

	let parsed_name: string | null = ""

	if (isValidEmail(contact_name)) {
		parsed_name = getWordBeforeAt(contact_name)
		if (parsed_name)
			parsed_name = capitalizeFirstLetter(parsed_name)
	}
	else 
		parsed_name = capitalizeFirstLetter(contact_name)
	return (
		parsed_name
	)
}

const SentFranchiseeTable = (props: TableProps) => {
	const {action, dialogs, handout, filter} = props

	const { t } = useTranslation('franchise_table')

	const columns = [
		{ field: 'id', headerName: 'ID', hide: true, minWidth:50, flex: 2 },
		{ field: 'tenant_name', headerName: t('name'), minWidth: 250, flex: 2 },
		{ field: 'company_owner_name', headerName: t('franchise_owner'), minWidth: 200, flex: 2 },
		{ field: 'city', headerName: t('city'), minWidth: 200, flex: 2 },
        { field: 'num_children', headerName: t('num_children'), minWidth: 100, flex: 2}
	]

    return (
		<>
        <Table
			dialogs={dialogs}
			action={action}
            filter={filter}
			columns={columns}
			page_size={100}
			amount_of_rows_selection={[100]}
            sort_by_asc="tenant_name"
		/>
		</>
    )
}

export default SentFranchiseeTable