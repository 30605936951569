import HandoutsActions from "@actions/HandoutsActions/handoutsActions";
import DatePickerObjectNastix from "@components/pickers/DatePickerObjectNastix";
import { DialogComponentProps } from "@components/tables/Table/Table";
import { MessageContext } from "@contexts/MessageContext";
import VerifiedIcon from '@mui/icons-material/Verified'
import { Button, Typography } from "@mui/material";
import { TableActionButon } from "@styles/Buttons";
import { DialogDivider, INastixDialog, INastixDialogTitle } from "@styles/Dialog";
import { DialogProps } from "@utils/dialogProps/DialogProps";
import { Dayjs } from "dayjs";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const MarkShirtsAsSentFranchise = (props: DialogProps) => {
    const {ids, state_manager, onClose, open} = props

    // const { t } = useTranslation('handouts')
    const { t } = useTranslation('navigation')

    const {setMessage} = useContext(MessageContext)

    const [disable_button, setDisableButton] = useState(false)
    const [collection_date, setCollectionDate] = useState<Record<string, Dayjs | null>>({
        'collection_date': null,
    })

    const handleSubmit = () => {
        setDisableButton(true)
        if (!collection_date['collection_date']) {
			setMessage(props => ({...props, error: "Please fill out the collection date"}))
			setDisableButton(false)
			return
		}

        const handouts_actions = new HandoutsActions()
        const data = {
            ids: ids,
            collection_date: collection_date,
            item: 'tshirt',
            franchise: true
        }
        handouts_actions.create(data)
        .then(data => {
            setMessage(props => ({...props, success: t('tshirts_marked_as_sent')}))
        })
        .catch(error => {
            setMessage(props => ({...props, error: error.message}))
            setDisableButton(false)
        })
        .finally(async () => {
            return state_manager.setGetAction(undefined, undefined, {'tshirt': false})

            .then(() => {
                setDisableButton(false)
                handleClose()
            })
        })
    }

    const handleClose = () => {
        onClose()
    }

    return (
        <INastixDialog onClose={handleClose} open={open}>
            <INastixDialogTitle> {t('mark_as_sent')} </INastixDialogTitle>
            <DialogDivider/>
            <Typography>
                {t('please_select_the_date_at_which_these_tshirts_will_be_or_have_been_collected')}
            </Typography>
            <DatePickerObjectNastix
                disabled={false}
                dates={collection_date}
                setDates={setCollectionDate}
                dates_key='collection_date'
                label={t('collection_date')}
            />
            <Button disabled={disable_button} variant='contained' onClick={handleSubmit}> {t('confirm')} </Button>
        </INastixDialog>
    )
}

const MarkShirtsAsSentFranchiseHandler = (props: DialogComponentProps) => {
    const {disabled, ids, state_manager} = props

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    // const { t } = useTranslation('handouts')
    const { t } = useTranslation('navigation')

    return (
        <>
            <TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<VerifiedIcon/>}>
                <Typography> {t('mark_as_sent')} </Typography>
            </TableActionButon>
            <MarkShirtsAsSentFranchise ids={ids} state_manager={state_manager} open={open} onClose={handleClose} />
        </>
    )
}

export default MarkShirtsAsSentFranchiseHandler