import { useTranslation } from "react-i18next";
import { DialogProps } from "@utils/dialogProps/DialogProps";
import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from "@styles/Dialog";
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TableCell, TextField, Typography } from "@mui/material";
import { DialogComponentProps } from "@components/tables/Table/Table";
import { useContext, useEffect, useState } from "react";
import { TableActionButon } from '@styles/Buttons'
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'
import { MessageContext } from "@contexts/MessageContext";
import { Child } from "@utils/interfaces/interfaces";
import ChildActions from "@actions/CRUDActions/ChildActions/childActions";
import WarnAboutInvalidChildren from "../MemberCertificatesDialogHandler/WarnAboutInvalidChildren";
import { Label } from "@mui/icons-material";


const ForwardChildToNewYearDialog = (props: DialogProps) => {
    const { ids, state_manager, onClose, open } = props
    const { setMessage } = useContext(MessageContext)
    const { t } = useTranslation('children')

    const [child_id, setChildId] = useState()
    const [shirt_size, setShirtSize] = useState('')
    const [is_ready, setIsReady] = useState(false)

    useEffect(() => {
        if (ids.length > 0) {
            const id = ids[0]
            const child = state_manager.data.find(obj => obj.id === id)
            setChildId(child.id)
            setShirtSize(child.shirt_size)
            setIsReady(child.not_ready_for_transfer)
        }
    }, [ids])

    const handleTransfer = async () => {
        if (child_id) {
            const child_actions = new ChildActions()
            child_actions.forwardChildren([{'id': child_id, 'shirt_size': shirt_size}])
            .then(() => {
                handleClose()
                state_manager.setGetAction(undefined, undefined, {is_old: true, archive: false})
            })
            .catch((error: any) => {
                console.log(error)
                setMessage((props) => ({ ...props, error: error }))
            })
        }
    }

    const handleShirtChange = (event: SelectChangeEvent) => {
        setShirtSize(event.target.value as string)
    }
    
    const handleClose = () => {
        onClose()
    }

    const cellStyle = {
        textAlign: 'center', // Center align the cell content horizontally
        verticalAlign: 'middle', // Center align the cell content vertically
    }

    return (
        <INastixDialog onClose={handleClose} open={open}>
            <INastixDialogTitle>{t("confirm_forward")}</INastixDialogTitle>
            <DialogDivider />
            
            <br/>
            { is_ready ?
                <Typography>
                    {t('childs_school_has_not_been_forwarded_yet')}
                </Typography> 
                :
                <Typography>
                    {t('are_you_sure_you_want_to_forward_member_to_the_new_year')}
                </Typography> 
            }
            <br/>
            <FormControl fullWidth>
                <InputLabel id="shirt-select-label">{t('select_shirt_size')}</InputLabel>
                <Select
                    labelId="shirt-select-label"
                    id="shirt-select"
                    name='shirt_size'
                    value={shirt_size}
                    label={t('select_shirt_size')}
                    onChange={handleShirtChange}
                    fullWidth
                    required
                >
                    {/* <MenuItem value={0}> 2-3 Months </MenuItem> */}
                    <MenuItem value={1}> 3-6 Months </MenuItem>
                    <MenuItem value={2}> 6-12 Months </MenuItem>
                    <MenuItem value={3}> 1-2 Years</MenuItem>
                    <MenuItem value={4}> 2-3 Years</MenuItem>
                    <MenuItem value={5}> 3-4 Years</MenuItem>
                    <MenuItem value={6}> 4-5 Years</MenuItem>
                    <MenuItem value={7}> 5-6 Years</MenuItem>
                    <MenuItem value={8}> 7-8 Years</MenuItem>
                    <MenuItem value={9}> 9-10 Years</MenuItem>
                    <MenuItem value={10}> 11-12 Years</MenuItem>
                    <MenuItem value={11}> Extra small</MenuItem>
                    <MenuItem value={12}> Small</MenuItem>
                    <MenuItem value={13}> Medium</MenuItem>
                    <MenuItem value={14}> Large</MenuItem>
                    <MenuItem value={15}> Extra large</MenuItem>
                </Select>
            </FormControl>
            
            <DialogButton variant='contained' onClick={handleTransfer}>
                {t('forward')}
            </DialogButton>
        </INastixDialog>
    )
}

const ForwardChildToNewYearDialogHandler = (props: DialogComponentProps) => {
    const { disabled, ids, state_manager } = props
    const [open, setOpen] = useState(false)
    const { t } = useTranslation('children')

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<TransferWithinAStationIcon />}>
                <Typography>{t('forward')}</Typography>
            </TableActionButon>
            <ForwardChildToNewYearDialog
                ids={ids}
                state_manager={state_manager}
                open={open}
                onClose={handleClose}
            />
        </>
    )
}

export default ForwardChildToNewYearDialogHandler























// import { useTranslation } from "react-i18next";
// import { DialogForIdsProps } from "../interfaces/interface";
// import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from "@styles/Dialog";
// import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TableCell, TextField, Typography } from "@mui/material";
// import { DialogComponentProps } from "@components/tables/Table/Table";
// import { useContext, useEffect, useState } from "react";
// import { TableActionButon } from '@styles/Buttons'
// import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'
// import { MessageContext } from "@contexts/MessageContext";
// import { Child } from "@utils/interfaces/interfaces";
// import ChildActions from "@actions/CRUDActions/ChildActions/childActions";
// import WarnAboutInvalidChildren from "../MemberCertificatesDialogHandler/WarnAboutInvalidChildren";
// import { Label } from "@mui/icons-material";


// const ForwardChildToNewYearDialog = (props: DialogForIdsProps) => {
//     const { ids, state_manager, onClose, open } = props
//     const { setMessage } = useContext(MessageContext)
//     const { t } = useTranslation('children')


//     const [children_ready, setChildrenReady] = useState<Child[]>()
//     const [children_not_ready, setChildrenNotReady] = useState<Child[]>()

//     useEffect(() => {
//         const idsToTransfer = ids.map(id => parseInt(id, 10))
    
//         const children_to_transfer = state_manager.data.filter((child: Child) =>
//             idsToTransfer.includes(child.id)
//         )

//         let ready_data: Child[] = []
//         let not_ready_data: Child[] = []
//         for (let i = 0; i < children_to_transfer.length; i++) {
//             if (children_to_transfer[i].not_ready_for_transfer)
//                 not_ready_data = [...not_ready_data, children_to_transfer[i]]
//             else
//                 ready_data = [...ready_data, children_to_transfer[i]]
//         }

//         setChildrenReady(ready_data)
//         setChildrenNotReady(not_ready_data)
//     }, [ids])

//     const handleTransfer = async () => {
//         if (children_ready) {
//             const child_actions = new ChildActions()
//             child_actions.forwardChildren(children_ready)
//             .then(() => {
//                 handleClose()
//                 state_manager.setGetAction(undefined, undefined, {is_old: true, archive: false})
//             })
//             .catch((error: any) => {
//                 console.log(error)
//                 setMessage((props) => ({ ...props, error: error }))
//             })
//         }
//     }

//     const handleShirtChange = (event: SelectChangeEvent) => {
//         if (children_ready) {
//             let updated_children = children_ready
//             updated_children[Number(event.target.name)].shirt_size = event.target.value
//             setChildrenReady(updated_children)
            
//         }
//     }
    
//     const handleClose = () => {
//         onClose()
//     }

//     const cellStyle = {
//         textAlign: 'center', // Center align the cell content horizontally
//         verticalAlign: 'middle', // Center align the cell content vertically
//     }

//     return (
//         <INastixDialog onClose={handleClose} open={open}>
//             <INastixDialogTitle>{t("confirm_forward")}</INastixDialogTitle>
//             <DialogDivider />
//             <Typography>
//                 {t('are_you_sure_you_want_to_forward', { count: ids.length })} {t(ids.length === 1 ? 'member_to_the_new_year' : 'members_to_the_new_year')}?
//             </Typography>
//             <br/>
//             { children_not_ready &&
//                 <WarnAboutInvalidChildren
//                     alert_message={"Childrens schools have not been forawrded yet"}
//                     cell_headings={[<Typography>School forwarded</Typography>]}
//                     cell_rows={
//                         [<TableCell sx={{...cellStyle, color: 'red'}}>No</TableCell>]
//                     }
//                     children={children_not_ready}
//                 />
//             }
//             { Array.isArray(children_ready) && children_ready.map((child, index) => 
//             (
//                 <>
//                     <Grid container spacing={2}>
//                         <Grid item md={6}>
//                             <Typography variant="body1" fontSize="1.1rem" style={{ textAlign: "center" }}>
//                                 {child.username}
//                             </Typography>
//                         </Grid>
//                         <Grid item md={6}>
//                             <FormControl fullWidth>
//                                 <InputLabel id="shirt-select-label">{t('select_shirt_size')}</InputLabel>
//                                 <Select
//                                     labelId="shirt-select-label"
//                                     id={index.toString()}
//                                     name={index.toString()}
//                                     value={child.shirt_size}
//                                     label={t('select_shirt_size')}
//                                     onChange={handleShirtChange}
//                                     fullWidth
//                                     required
//                                 >
//                                     <MenuItem value={0}> 2-3 Months </MenuItem>
//                                     <MenuItem value={1}> 3-6 Months </MenuItem>
//                                     <MenuItem value={2}> 6-12 Months </MenuItem>
//                                     <MenuItem value={3}> 1-2 Years</MenuItem>
//                                     <MenuItem value={4}> 2-3 Years</MenuItem>
//                                     <MenuItem value={5}> 3-4 Years</MenuItem>
//                                     <MenuItem value={6}> 4-5 Years</MenuItem>
//                                     <MenuItem value={7}> 5-6 Years</MenuItem>
//                                     <MenuItem value={8}> 7-8 Years</MenuItem>
//                                     <MenuItem value={9}> 9-10 Years</MenuItem>
//                                     <MenuItem value={10}> 11-12 Years</MenuItem>
//                                     <MenuItem value={11}>{t('extra_small')}</MenuItem>
//                                     <MenuItem value={12}>{t('small')}</MenuItem>
//                                     <MenuItem value={13}>{t('medium')}</MenuItem>
//                                     <MenuItem value={14}>{t('large')}</MenuItem>
//                                     <MenuItem value={15}>{t('extra_large')}</MenuItem>
//                                 </Select>
//                             </FormControl>
//                         </Grid>
//                     </Grid>
//                     <br/>
//                     <DialogDivider color="grey"/>
//                     <br/>
//                 </>
//             ))}
//             <DialogButton variant='contained' onClick={handleTransfer}>
//                 {t('forward')}
//             </DialogButton>
//         </INastixDialog>
//     )
// }

// const ForwardChildToNewYearDialogHandler = (props: DialogComponentProps) => {
//     const { disabled, ids, state_manager } = props
//     const [open, setOpen] = useState(false)
//     const { t } = useTranslation('children')

//     const handleClickOpen = () => {
//         setOpen(true)
//     }

//     const handleClose = () => {
//         setOpen(false)
//     }

//     return (
//         <>
//             <TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<TransferWithinAStationIcon />}>
//                 <Typography>{t('forward')}</Typography>
//             </TableActionButon>
//             <ForwardChildToNewYearDialog
//                 ids={ids}
//                 state_manager={state_manager}
//                 open={open}
//                 onClose={handleClose}
//             />
//         </>
//     )
// }

// export default ForwardChildToNewYearDialogHandler