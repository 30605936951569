import { CRUDAction } from "@actions/Actions/actions";
import Table, { DialogComponent } from "@components/tables/Table/Table";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { HandoutType } from "@utils/interfaces/interfaces";
import { getLocalStorageCompany } from "@utils/localStorage/company";
import { useTranslation } from "react-i18next";


export interface TableProps {
    action: CRUDAction
    dialogs: DialogComponent[]
    handout: HandoutType
    filter: Object
}

export const handoutsTableValueGetter = (params: any) => {
	if (params.field === 'child_start_date') {
		const datePart = new Date(params.value).toISOString().split('T')[0]
		return datePart
	}
}

export const renderConnectedFranchise = (params: any) => {
	if (params.row.franchise_name) {
		return params.row.franchise_name
	}
	return ""
}

const SentChildTable = (props: TableProps) => {
    const {action, dialogs, handout, filter} = props

    // const { t } = useTranslation('handouts')
    const { t } = useTranslation('navigation')

    const company = getLocalStorageCompany()

    const is_franchisee = company.acts_like_a_franchise

    const columns = [
        { field: 'id', headerName: 'id' },
        { field: 'child_name', headerName: t('name'), minWidth: 150, flex: 0.2 },
        { field: 'franchise_name', headerName: "Franchise", cellClassName: '', minWidth: 270, renderCell: renderConnectedFranchise},
        { field: 'school_name', headerName: t('school'), minWidth: 150, flex: 0.2 },
        { field: 'child_start_date', sortable: false, filterable: false, headerName: t('start_date'), minWidth: 120, flex: 0.2, valueGetter: handoutsTableValueGetter},
        { field: 'tshirt_date', headerName: t('collection_date'), minWidth: 150, flex: 0.2 },
        { field: 'workbook_date', headerName: t('collection_date'), minWidth: 150, flex: 0.2 },
        { field: 'buff_date', headerName: t('collection_date'), minWidth: 150, flex: 0.2 },
        { field: 'medal_date', headerName: t('collection_date'), minWidth: 150, flex: 0.2 },
    ]

    const column_visibility_model: GridColumnVisibilityModel = {}
    
    if (is_franchisee)
        column_visibility_model['franchise_name'] = false
    if (handout === HandoutType.TSHIRT) {
        column_visibility_model['workbook_date'] = false
        column_visibility_model['buff_date'] = false
        column_visibility_model['medal_date'] = false
    } else if (handout === HandoutType.WORKBOOK) {
        column_visibility_model['tshirt_date'] = false
        column_visibility_model['buff_date'] = false
        column_visibility_model['medal_date'] = false
    } else if (handout === HandoutType.BUFF) {
        column_visibility_model['tshirt_date'] = false
        column_visibility_model['workbook_date'] = false
        column_visibility_model['medal_date'] = false
    } else if (handout === HandoutType.MEDAL) {
        column_visibility_model['tshirt_date'] = false
        column_visibility_model['workbook_date'] = false
        column_visibility_model['buff_date'] = false
    }

    return (
        <Table
            dialogs={dialogs}
            action={action}
            filter={filter}
            columns={columns}
            column_visibility_model={column_visibility_model}
            page_size={100}
            amount_of_rows_selection={[100]}
            sort_by_asc="child_name"
        />
    )
}

export default SentChildTable