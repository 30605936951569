import { CRUDAction } from "@actions/Actions/actions";
import HandoutsHandler from "@adapters/handlers/CRUDHandlers/HandoutsHandlers/handoutsHandlers";


class HandoutsActions extends CRUDAction {
    handler: HandoutsHandler

    constructor() {
        super()
        this.handler = new HandoutsHandler()
    }
}

export default HandoutsActions