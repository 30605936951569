import React, {useState, useContext} from 'react'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Button, Typography } from '@mui/material'
import HailIcon from '@mui/icons-material/Hail'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Badge from '@mui/material/Badge'
import AccountCircle from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import { useMediaQuery, Theme } from '@mui/material'
import SchoolIcon from '@mui/icons-material/School'
import LineAxisIcon from '@mui/icons-material/LineAxis'
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom'
import BusinessIcon from '@mui/icons-material/Business'
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'
import SummarizeIcon from '@mui/icons-material/Summarize'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import Diversity2Icon from '@mui/icons-material/Diversity2'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import LocalMallIcon from '@mui/icons-material/LocalMall'

import SourceIcon from '@mui/icons-material/Source'

import { NumOfCompanyNotificationsContext, NumOfNotificationsContext } from '@contexts/NumOfNotificationsContext'
import { logout } from '@adapters/helpers/logout'
import { companyIsInGroup, companyPermissionValid, isAMemberOfThisGroupNormalisedGroups, permissionValid } from '@utils/utils/util'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { UserPermissions } from '@utils/enums/permissions'
import { MessageContext } from '@contexts/MessageContext'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import { IsLoggedInContext } from '@contexts/IsLoggedInContext'
import { LocalstorageUserExistsContext } from '@contexts/LocalstorageUserExistsContext'
import { LocalstorageCompanyExistsContext } from '@contexts/LocalstorageCompanyExistsContext'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import ProfileSection, { ProfileSectionStylesObject } from '@pages/Settings/Settings/ProfileSection'
import { CompanyType, InastixSystemUserType, UserType } from '@utils/enums/enums'

import log from 'loglevel'
import { SVGShirtIcon } from '@pages/Reporting/icons'
import { WorkspacePremium } from '@mui/icons-material'


const SchoolsItem = () => {
    const navigate = useNavigate()

    const user = getLocalStorageUser()
	const connected_company = getLocalStorageCompany()

    const handleClick = (path: string) => {
		navigate("/" + path)
	}

    return (
        <>
        {permissionValid(user, 'view_schoolmodel') && companyPermissionValid(connected_company, 'view_schoolmodel') &&
            <ListItem key="Schools" disablePadding>
                <ListItemButton onClick={() => handleClick('schools')}>
                    <ListItemIcon>
                        <SchoolIcon sx={{color: 'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Schools"} />
                    <ChevronRightIcon fontSize='small'/>
                </ListItemButton>
            </ListItem>
        }
        </>
    )
}

const FranchisesItem = () => {
    const navigate = useNavigate()

    const user = getLocalStorageUser()
	const connected_company = getLocalStorageCompany()

    const handleClick = (path: string) => {
		navigate("/" + path)
	}

    return (
        <>
        {permissionValid(user, 'view_tenant') && companyPermissionValid(connected_company, 'view_tenant') && 
            <ListItem key="Franchisees" disablePadding>
                <ListItemButton onClick={() => handleClick('franchisees')}>
                    <ListItemIcon>
                        <BusinessIcon sx={{color: 'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Franchisees"} />
                    <ChevronRightIcon fontSize='small'/>
                </ListItemButton>
            </ListItem>
        }
        </>
    )
}


const ReportingItem = () => {
    const navigate = useNavigate()

    const handleClick = (path: string) => {
		navigate("/" + path)
	}

    return (
        <>
        <ListItem key="Reporting" disablePadding>
            <ListItemButton onClick={() => handleClick('reporting')}>
                <ListItemIcon>
                    <SummarizeIcon sx={{color: 'white'}}/>
                </ListItemIcon>
                <ListItemText primary={"Reporting"} />
                <ChevronRightIcon fontSize='small'/>
            </ListItemButton>
        </ListItem>
        </>
    )
}


const MinastixMemberPool = () => {
    const navigate = useNavigate()

    const handleClick = (path: string) => {
		navigate("/" + path)
	}

    return (
        <>
        <ListItem key="Minastix Child Pool" disablePadding>
            <ListItemButton onClick={() => handleClick('minastix-member-pool')}>
                <ListItemIcon>
                    <Diversity2Icon sx={{color: 'white'}}/>
                </ListItemIcon>
                <ListItemText primary={"Member Pool"} />
                <ChevronRightIcon fontSize='small'/>
            </ListItemButton>
        </ListItem>
        </>
    )
}


const MobileItems = () => {
    const navigate = useNavigate()
	const { t } = useTranslation('navigation')

	const user = getLocalStorageUser()
	const connected_company = getLocalStorageCompany()

    const handleClick = (path: string) => {
		navigate("/" + path)
	}

    const [open, setOpen] = React.useState(false)

    const handleDashboardNavItemClick = () => {
        setOpen(!open)
    }

    return (
        <>
        {permissionValid(user, 'view_userdashboardsettingsmodel') && 
            user.is_instructor ?
                <ListItem key="Dashboard" disablePadding sx={{display: 'flex', flexDirection: 'column', alignItems: 'stretch'}}>
                    <ListItemButton onClick={handleDashboardNavItemClick}>
                        <ListItemIcon>
                            <LineAxisIcon sx={{color: 'white'}}/>
                        </ListItemIcon>
                        <ListItemText primary={"Dashboard"} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                        {/* <ChevronRightIcon fontSize='small'/> */}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit sx={{marginLeft: '4rem'}}>
                        <List component="div" disablePadding>
                            <ListItemButton onClick={() => handleClick('dashboard/franchise')}>
                                <ListItemText primary={"Your Franchise"} sx={{'& .MuiTypography-root': {fontSize: '0.8rem'}}}/>
                                <ChevronRightIcon fontSize='small'/>
                            </ListItemButton>
                            <ListItemButton onClick={() => handleClick('dashboard/instructor')}>
                                <ListItemText primary={"Your Instructor"} sx={{'& .MuiTypography-root': {fontSize: '0.8rem'}}} />
                                <ChevronRightIcon fontSize='small'/>
                            </ListItemButton>
                        </List>
                    </Collapse>
                </ListItem>
            : 
            <ListItem key="Dashboard" disablePadding>
                <ListItemButton onClick={() => handleClick('dashboard')}>
                    <ListItemIcon>
                        <LineAxisIcon sx={{color: 'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Dashboard"} />
                    <ChevronRightIcon fontSize='small'/>
                </ListItemButton>
            </ListItem>
        }
        {permissionValid(user, 'view_companyclassmodel') &&
            <ListItem key="Classes" disablePadding>
                <ListItemButton onClick={() => handleClick('classes')}>
                    <ListItemIcon>
                        <HistoryEduIcon sx={{color: 'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Classes"} />
                    <ChevronRightIcon fontSize='small'/>
                </ListItemButton>
            </ListItem>
        }
        {permissionValid(user, 'view_childmodel') &&
            <ListItem key="Members" disablePadding>
                <ListItemButton onClick={() => handleClick('members')}>
                    <ListItemIcon>
                        <FamilyRestroomIcon sx={{color: 'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t('members')} />
                    <ChevronRightIcon fontSize='small'/>
                </ListItemButton>
            </ListItem>
        }
        {/* {permissionValid(user, 'view_childmodel') &&
            <ListItem key="Payments" disablePadding>
                <ListItemButton onClick={() => handleClick('payments')}>
                    <ListItemIcon>
                        <FamilyRestroomIcon sx={{color: 'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t('payments')} />
                    <ChevronRightIcon fontSize='small'/>
                </ListItemButton>
            </ListItem>
        } */}
        {connected_company.acts_like_a_franchise && <SchoolsItem/>}
        <FranchisesItem/>
        <ReportingItem/>
        </>
    )
}

const TabletItems = () => {
	const connected_company = getLocalStorageCompany()

    return (
        <>
        {connected_company.acts_like_a_franchise && <SchoolsItem/>}
        <FranchisesItem/>
        <ReportingItem/>
        </>
    )
}

const PrimaryDrawer = () => {
    const navigate = useNavigate()
	const { t } = useTranslation('navigation')
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const is_tablet_screen = useMediaQuery((theme: Theme) => `(max-width:${theme.breakpoints.values.md}px) and (min-width:${theme.breakpoints.values.sm}px)`);

	const {num_of_notifications} = useContext(NumOfNotificationsContext)
	const {num_of_company_notifications} = useContext(NumOfCompanyNotificationsContext)
	const {setMessage} = useContext(MessageContext)
	const {setNumOfRequests} = useContext(NumOfRequestsContext)
    const {setIsLoggedIn} = useContext(IsLoggedInContext)
    const {setLocalstorageUserExists} = useContext(LocalstorageUserExistsContext)
    const {setLocalstorageCompanyExists} = useContext(LocalstorageCompanyExistsContext)

    const [primary_drawer_open, setPrimaryDrawerOpen] = useState(false)

    const user = getLocalStorageUser()
	const connected_company = getLocalStorageCompany()

    const toggleDrawer = ( open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return
        }

        setPrimaryDrawerOpen(open)
    }

    const handleClick = (path: string) => {
		navigate("/" + path)
	}

    const display_pages_word = permissionValid(user, UserPermissions.VIEW_USERS) || permissionValid(user, UserPermissions.VIEW_FINANCES)

    const logoutWrapper = () => {
        setNumOfRequests(props => props + 1)
        logout(navigate, setIsLoggedIn, setLocalstorageUserExists, setLocalstorageCompanyExists)
        .then(message => {
            log.info(message)
        })
        .catch(error => {
            setMessage(props => ({...props, error: error.message}))
        })
        .finally(() => {
            setNumOfRequests(props => props - 1)
        })
    }

    const profile_section_styles: ProfileSectionStylesObject = {
        profile_box: {width: '100%', justifyContent: 'start', gap: '0.5rem', marginLeft: '1rem', marginTop: '0.5rem', textTransform: 'capitalize'},
        username: {color: 'white', margin: 0},
        group_name: {color: 'antiquewhite'}
    }

    const company = getLocalStorageCompany()
	const acts_like_a_franchise = company.acts_like_a_franchise
	const is_franchisor = companyIsInGroup(company, CompanyType.FRANCHISOR)

    const list = () => (
        <Box
            sx={{ 
                width: 250, display: 'flex',
                flexDirection: 'column',
                height: '100%',
                backgroundColor: theme => theme.palette.primary.main,
                color: 'white'
            }}
            role="presentation"
        >
        <List sx={{flexGrow: 1, paddingTop: 0}}>
            <Button onClick={() => handleClick('settings/profile')} sx={{padding: 0, width: '100%', justifyContent: 'start'}}>
                <ProfileSection styles={profile_section_styles} hide_edit_profile={true}/>
                {/* <Typography color={'white'} textTransform={'none'} p={'1rem'}>{<b>{user.username}</b>} </Typography> */}
            </Button>
            <Divider sx={{backgroundColor: 'white', marginBottom: '0.4rem'}}/>
            {permissionValid(user, UserPermissions.VIEW_NOTIFICATIONS) && 
                <ListItem sx={{marginTop: '0.5rem'}} key="Notifications" disablePadding>
                    <ListItemButton onClick={() => handleClick('notifications')}>
                        <ListItemIcon>
                            <Badge badgeContent={num_of_notifications + num_of_company_notifications} color="error">
                                <NotificationsIcon sx={{color: 'white'}}/>
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary={t("notifications")} />
                        <ChevronRightIcon fontSize='small'/>
                    </ListItemButton>
                </ListItem>
            }
            <ListItem key="Profile" disablePadding>
                <ListItemButton onClick={() => handleClick('settings/profile')}>
                    <ListItemIcon>
                        <AccountCircle sx={{color: 'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t("profile")} />
                    <ChevronRightIcon fontSize='small'/>
                </ListItemButton>
            </ListItem>
            {permissionValid(user, UserPermissions.VIEW_FZONE) && 
                <ListItem key="FZone" disablePadding>
                    <ListItemButton onClick={() => handleClick('fzone')}>
                        <ListItemIcon>
                            <SourceIcon sx={{color: 'white'}}/>
                        </ListItemIcon>
                        <ListItemText primary={"FZone"} />
                        <ChevronRightIcon fontSize='small'/>
                    </ListItemButton>
                </ListItem>
            }
            {process.env.REACT_APP_ENVIRONMENT === 'development' && (isAMemberOfThisGroupNormalisedGroups(user, InastixSystemUserType.FRANCHISE_COSTING_ADMIN) || isAMemberOfThisGroupNormalisedGroups(user, InastixSystemUserType.INASTIX_DEV)) && 
                <ListItem key="Franchise Costing" disablePadding>
                    <ListItemButton onClick={() => handleClick('franchise-costing')}>
                        <ListItemIcon>
                            <PointOfSaleIcon sx={{color: 'white'}}/>
                        </ListItemIcon>
                        <ListItemText primary={"Franchise Costing"} />
                        <ChevronRightIcon fontSize='small'/>
                    </ListItemButton>
                </ListItem>
            }
            {process.env.REACT_APP_ENVIRONMENT === 'development' && (isAMemberOfThisGroupNormalisedGroups(user, InastixSystemUserType.MEMBER_HANDOUTS_ADMIN) || isAMemberOfThisGroupNormalisedGroups(user, InastixSystemUserType.INASTIX_DEV)) && 
                <ListItem key="Member Handouts" disablePadding>
                    <ListItemButton onClick={() => handleClick('member-handouts')}>
                        <ListItemIcon>
                            <LocalMallIcon sx={{color: 'white'}}/>
                        </ListItemIcon>
                        <ListItemText primary={"Member Handouts"} />
                        <ChevronRightIcon fontSize='small'/>
                    </ListItemButton>
                </ListItem>
            }
            <Divider sx={{backgroundColor: 'white', marginTop: '0.4rem'}}/>
            {display_pages_word && <Typography sx={{padding: '1rem', fontWeight: '600'}}> {t('pages')} </Typography>}
            {/* <ListItem key="Orders" disablePadding>
                <ListItemButton onClick={() => handleClick('orders')}>
                    <ListItemIcon>
                        <ShoppingCartIcon sx={{color: 'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t("order")}/>
                    <ChevronRightIcon fontSize='small'/>
                </ListItemButton>
            </ListItem> */}
            {is_tablet_screen && <TabletItems/>}
            {is_small_screen && <MobileItems/>}
            {!connected_company.acts_like_a_franchise && <SchoolsItem/>}
            {permissionValid(user, UserPermissions.VIEW_USERS) && 
                <ListItem key="Users" disablePadding>
                    <ListItemButton onClick={() => handleClick('users')}>
                        <ListItemIcon>
                            <HailIcon sx={{color: 'white'}}/>
                        </ListItemIcon>
                        <ListItemText primary={t('users')} />
                        <ChevronRightIcon fontSize='small'/>
                    </ListItemButton>
                </ListItem>
            }
            {permissionValid(user, UserPermissions.VIEW_FINANCES) &&
                <ListItem key="Finance" disablePadding>
                    <ListItemButton onClick={() => {
                            let url
                            if (is_franchisor || !acts_like_a_franchise)
                                url = 'finance/royalty-invoices'
                            else
                                url = 'finance/member-invoices'
                            return handleClick(url)
                        }
                    }>
                        <ListItemIcon>
                            <AccountBalanceIcon sx={{color: 'white'}}/>
                        </ListItemIcon>
                        <ListItemText primary={t("finance")} />
                        <ChevronRightIcon fontSize='small'/>
                    </ListItemButton>
                </ListItem>
            }
            {permissionValid(user, UserPermissions.EDIT_CHILDREN) &&
                <ListItem key="Handouts" disablePadding>
                    <ListItemButton onClick={() => handleClick('handouts')}>
                        <ListItemIcon>
                            <WorkspacePremium sx={{color: 'white'}}/>
                        </ListItemIcon>
                        <ListItemText primary={t("handouts")}/>
                        <ChevronRightIcon fontSize='small'/>
                    </ListItemButton>
                </ListItem>
            }
            <MinastixMemberPool/>
        </List>
        <Divider sx={{backgroundColor: 'white'}}/>
        <List sx={{paddingTop: 0}}>
            <Typography sx={{padding: '1rem', fontWeight: '600'}}> {t('support')} </Typography>
            <ListItem key="Help" disablePadding>
                <ListItemButton onClick={() => handleClick('contact')}>
                    <ListItemIcon>
                        <HelpOutlineIcon sx={{color: 'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t("help")} />
                    <ChevronRightIcon fontSize='small'/>
                </ListItemButton>
            </ListItem>
            <ListItem key="Logout" disablePadding>
                <ListItemButton onClick={logoutWrapper}>
                    <ListItemIcon>
                        <LogoutIcon sx={{color: 'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t("logout")} />
                    <ChevronRightIcon fontSize='small'/>
                </ListItemButton>
            </ListItem>
            {/* DONT REMOVE. ADD BACK IN WHEN INSTALL APP IS WORKING */}
            {/* <ListItem key="cee" disablePadding>
                <div style={{padding: '0.5rem', width: '100%'}}>
                    <InstallApp button_styles={{width: '100%', height: '100%'}}/>
                </div>
            </ListItem> */}
        </List>
        </Box>
    )

    return (
        <>
        <IconButton onClick={toggleDrawer(true)}>
            <MenuIcon sx={{color: 'white'}}/>
        </IconButton>
        <Drawer
            anchor={'right'}
            open={primary_drawer_open}
            onClose={toggleDrawer(false)}
            sx={{'.MuiPaper-root': {
                backgroundColor: '#1f365c',
                height: '100%'
            }}}
        >
            {list()}
        </Drawer>
        </>
    )
}

export default PrimaryDrawer